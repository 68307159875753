import * as React from "react";

import { Admin, Resource } from 'react-admin';
import drfProvider from 'ra-data-django-rest-framework';
import { Datagrid, DateField, List, NumberField, TextField, TextInput } from 'react-admin';
import { QueryClient } from 'react-query';


let API_ENDPOINT;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  API_ENDPOINT = 'http://localhost:8000'
} else {
  API_ENDPOINT = 'https://api.tetun.org'
}
const dataProvider = drfProvider(API_ENDPOINT);


const projectFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <TextInput label="Recipient country" source="recipient_country" />,
    <TextInput label="Donor" source="reporting_org_ref" />,
];

const ProjectList = () => (
    <List filters={projectFilters} title="Asia-Pacific projects since 2020 for major donors" perPage={25}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="title" />
            <TextField source="recipient_country" />
            <TextField source="reporting_org_ref" label="Donor" />
            <DateField options={{ year: 'numeric' }} source="start" />
            <DateField options={{ year: 'numeric' }} source="end" />
            <NumberField source="budget" />
        </Datagrid>
    </List>
);

const App = () => {
    const queryClient = new QueryClient({
      // cache queries for 60 minutes
      defaultOptions: {
        queries: {
          staleTime: 60 * 60 * 1000, // 60 minutes
        },
      },
    });

  return (
    <Admin dataProvider={dataProvider} queryClient={queryClient}>
        <Resource name="projects" list={ProjectList} />
    </Admin>
  );
}

export default App;
